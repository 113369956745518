/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
import { navigate } from '@reach/router';
import React from 'react';
import styled from 'styled-components';
import Layout from '../components/Layout';

const Row = styled.div`
display: flex;
flex-direction: row;
flex-wrap: nowrap;
margin: 1rem 0rem;
& > p {
  padding: 0rem 1rem;
}
`;

const BillingDetailsPage = (props) => {
  const { name, email, phone } = props.location.state ? props.location.state : { name: '', email: '', phone: '' };

  if (!name) {
    if (typeof window !== 'undefined') { return navigate('/members/events'); }
  }

  return (
    <Layout title="Billing Details" subtitle="">
      <Row>
        <p style={{ fontWeight: 'bold' }}>Name:</p>
        <p>{name}</p>
      </Row>
      <Row>
        <p style={{ fontWeight: 'bold' }}>Email:</p>
        <p>{email}</p>
      </Row>
      <Row>
        <p style={{ fontWeight: 'bold' }}>Phone:</p>
        <p>{phone}</p>
      </Row>
    </Layout>
  );
};

export default BillingDetailsPage;
